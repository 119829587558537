/*eslint-disable*/
import axios from "axios";

const initData = {
    api: "https://api-staging.maximpricingtool.com/api/",
    // api: "http://localhost:8080/api/"
};

const tenant = process.env.TENANT;
const api = process.env.APIKEY;

export const postConexion = async (urlApi, objeto) => {
    try {
        const user = localStorage.getItem('user');
        const parsedData = JSON.parse(user);
        let url = initData.api + urlApi;
        const headers = { Authorization: `Bearer ${parsedData.token}` };
        // delete axios.defaults.headers.common["Authorization"];
        const response = await axios.post(url, objeto, { headers }).catch((err) => {
            if (err.response) {
                switch (err.response.status) {
                    case 400:
                        if (err.response.data.message === "Token does not exists.")
                            throw new Error(`No hay permiso para actualizar tu contraseña`);
                        else
                            throw new Error(
                                `El correo o contraseña ingresados son incorrectos`
                            );
                    case 403:
                        if (
                            err.response.data.message ===
                            "This code is already expired by Admin!"
                        )
                            throw new Error(`Tu código no es valido`);
                        else {
                            localStorage.setItem("accessToken", null);
                            throw new Error(`La autorización ha caducado, intenta de nuevo`);
                        }
                    case 401:
                        const originalRequest = err.config;
                        let refreshToken = localStorage.getItem("refreshToken");
                        if (refreshToken)
                            return axios
                                .post(initData.api + "users/token/refresh", {
                                    refreshToken: refreshToken,
                                })
                                .then((res) => {
                                    if (res.status === 200) {
                                        localStorage.setItem("accessToken", res.data.accessToken);
                                        return axios(originalRequest);
                                    }
                                });
                        break;
                    case 404:
                        throw new Error(
                            `El correo o contraseña ingresados son incorrectos`
                        );
                    case 409:
                        if (err.response.data.message === "Email address not valid")
                            throw new Error(`Correo electrónico no válido`);
                        else throw new Error(`El usuario ya poseé una cuenta`);
                    case 430:
                        throw new Error(
                            `Su último pago no es válido o no tienes datos de pago registrados. `
                        );
                    case 500:
                        console.error(err);
                    default:
                        throw new Error(
                            `La api responde pero con error ${err.response.status}`
                        );
                }
            } else if (err.request) {
                throw new Error(`No existe conexión`);
            } else {
                throw err;
            }
        });
        const resp = response.data;
        return resp;
    } catch (err) {
        /* if (err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
          err.message === "La autorización ha caducado, intenta de nuevo")
          window.location.reload(); */
        return Promise.reject((err.message && err) || "Something went wrong");
    }
};

export const getConexionParams = async (urlApi, params) => {
    try {
        // const token = await createToken(user);
        let url = `${initData.apiAltScore}${urlApi}?per-page=${params.perPage}&from-date=${params.fromDate}&to-date=${params.toDate}`;
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'api-key': "WMdQqEVFxO5FNS4Yb6QfGqM",
                'tenant': "maximcc",
                "Content-Type": "application/json"
            }
        };
        const response = await axios.request(config).catch((err) => {
            if (err.response) {
                switch (err.response.status) {
                    case 401:
                        const originalRequest = err.config;
                        let refreshToken = localStorage.getItem("refreshToken");
                        if (refreshToken)
                            return axios
                                .post(initData.api + "users/token/refresh", {
                                    refreshToken: refreshToken,
                                })
                                .then((res) => {
                                    if (res.status === 200) {
                                        localStorage.setItem("accessToken", res.data.accessToken);
                                        return axios(originalRequest);
                                    }
                                });
                        break;
                    case 403:
                        localStorage.setItem("accessToken", null);
                        throw new Error(`La autorización ha caducado, intenta de nuevo`);
                    case 404:
                        throw new Error(`Servicio no encontrado`);
                    case 409:
                        throw new Error(`El usuario ya poseé una cuenta`);
                    case 500:
                        if (
                            err.response.data.message ===
                            "No existe ningun candidato con los filtros enviados"
                        )
                            return `El documento se encuentra desactivado.`;
                        else throw new Error(`Por favor intente de nuevo, más tarde`);
                    default:
                        localStorage.setItem("accessToken", null);
                        throw new Error(
                            `La api responde pero con error ${err.response.status}`
                        );
                }
            } else if (err.request) {
                throw new Error(`No existe conexión`);
            } else {
                throw err;
            }
        });
        const resp = response.data;
        return resp;
    } catch (err) {
        if (
            err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
            err.message === "La autorización ha caducado, intenta de nuevo"
        )
            window.location.reload();
        return Promise.reject((err.message && err) || "Something went wrong");
    }
};

export const getConexion = async (urlApi, headers = "") => {
    try {
        // const token = await createToken(user);
        let url = initData.api + urlApi;
        // let auth = {
        //   headers: { Authorization: token },
        // };

        const customAxios = axios.create({
            headers: {
                common: {
                    // Remove any default headers
                    'X-Requested-With': null,
                    'Content-Type': null
                }
            }
        });
        delete axios.defaults.headers.common["Authorization"];
        const response = await axios.get(url, {responseType:'blob'}).catch((err) => {
            if (err.response) {
                switch (err.response.status) {
                    case 401:
                        const originalRequest = err.config;
                        let refreshToken = localStorage.getItem("refreshToken");
                        if (refreshToken)
                            return axios
                                .post(initData.api + "users/token/refresh", {
                                    refreshToken: refreshToken,
                                })
                                .then((res) => {
                                    if (res.status === 200) {
                                        localStorage.setItem("accessToken", res.data.accessToken);
                                        return axios(originalRequest);
                                    }
                                });
                        break;
                    case 403:
                        localStorage.setItem("accessToken", null);
                        throw new Error(`La autorización ha caducado, intenta de nuevo`);
                    case 404:
                        throw new Error(`Servicio no encontrado`);
                    case 409:
                        throw new Error(`El usuario ya poseé una cuenta`);
                    case 500:
                        if (
                            err.response.data.message ===
                            "Probably the document was disbled by admin."
                        )
                            throw new Error(`El documento se encuentra desactivado.`);
                        else throw new Error(`Por favor intente de nuevo, más tarde`);
                    default:
                        localStorage.setItem("accessToken", null);
                        throw new Error(
                            `La api responde pero con error ${err.response.status}`
                        );
                }
            } else if (err.request) {
                throw new Error(`No existe conexión`);
            } else {
                throw err;
            }
        });
        const resp = response.data;
        return resp;
    } catch (err) {
        if (
            err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
            err.message === "La autorización ha caducado, intenta de nuevo"
        )
            window.location.reload();
        return Promise.reject((err.message && err) || "Something went wrong");
    }
};

export const putConexion = async (urlApi, objeto) => {
    try {
        let url = initData.api + urlApi;
        let auth = {
            headers: { Authorization: localStorage.getItem("accessToken") },
        };
        const response = await axios.put(url, objeto, auth).catch((err) => {
            if (err.response) {
                switch (err.response.status) {
                    case 401:
                        const originalRequest = err.config;
                        let refreshToken = localStorage.getItem("refreshToken");
                        if (refreshToken)
                            return axios
                                .post(initData.api + "users/token/refresh", {
                                    refreshToken: refreshToken,
                                })
                                .then((res) => {
                                    if (res.status === 200) {
                                        localStorage.setItem("accessToken", res.data.accessToken);
                                        return axios(originalRequest);
                                    }
                                });
                        break;
                    case 403:
                        localStorage.setItem("accessToken", null);
                        throw new Error(`La autorización ha caducado, intenta de nuevo`);
                    case 404:
                        throw new Error(`Servicio no encontrado`);
                    case 409:
                        throw new Error(`El usuario ya poseé una cuenta`);
                    case 500:
                        throw new Error(`Por favor intente de nuevo, más tarde`);
                    default:
                        throw new Error(
                            `La api responde pero con error ${err.response.status}`
                        );
                }
            } else if (err.request) {
                throw new Error(`No existe conexión`);
            } else {
                throw err;
            }
        });
        const resp = response.data;
        return resp;
    } catch (err) {
        if (
            err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
            err.message === "La autorización ha caducado, intenta de nuevo"
        )
            window.location.reload();
        return Promise.reject((err.message && err) || "Something went wrong");
    }
};

export const deleteConexion = async (urlApi, objeto) => {
    try {
        let url = initData.api + urlApi;
        const response = await axios.delete(url, { data: objeto }).catch((err) => {
            if (err.response) {
                switch (err.response.status) {
                    case 401:
                        const originalRequest = err.config;
                        let refreshToken = localStorage.getItem("refreshToken");
                        if (refreshToken)
                            return axios
                                .post(initData.api + "users/token/refresh", {
                                    refreshToken: refreshToken,
                                })
                                .then((res) => {
                                    if (res.status === 200) {
                                        localStorage.setItem("accessToken", res.data.accessToken);
                                        return axios(originalRequest);
                                    }
                                });
                        break;
                    case 403:
                        localStorage.setItem("accessToken", null);
                        throw new Error(`La autorización ha caducado, intenta de nuevo`);
                    case 404:
                        throw new Error(`Servicio no encontrado`);
                    case 409:
                        throw new Error(`El usuario ya poseé una cuenta`);
                    case 500:
                        throw new Error(`Por favor intente de nuevo, más tarde`);
                    default:
                        throw new Error(
                            `La api responde pero con error ${err.response.status}`
                        );
                }
            } else if (err.request) {
                throw new Error(`No existe conexión`);
            } else {
                throw err;
            }
        });
        const resp = response.data;
        return resp;
    } catch (err) {
        if (
            err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
            err.message === "La autorización ha caducado, intenta de nuevo"
        )
            window.location.reload();
        return Promise.reject((err.message && err) || "Something went wrong");
    }
};

export const submit = async (urlApi, data, method) => {
    try {
        let url = initData.api + urlApi;
        let res = await axios({
            method: method,
            url: url,
            data: data,
        });
        return res;
    } catch (err) {
        return err;
    }
};

export const submitV2 = async (urlApi, data) => {
    return new Promise(async function (resolve, reject) {
        let url = initData.api + urlApi;
        await axios
            .post(url, data)
            .then(async (response) => {
                resolve({ code: true, message: response });
            })
            .catch(async (error) => {
                console.error(error.response.data);
                resolve({ code: false, message: error.response.data });
            });
    });
};
